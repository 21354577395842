import { gql, skipToken, useMutation, useSuspenseQuery } from '@apollo/client'
import { Mutation, Query } from '@promoboxx/graphql-gateway-types'
import jsCookie from 'js-cookie'
import React, {
  createContext,
  memo,
  startTransition,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import { jwtRef } from '../../config/ApolloClient'

import parsePbxxJwt from './parsePbxxJwt'

export interface AuthHeadersCookie {
  'access-token': string
  'client': string
  'expiry': string
  'token-type': string
  'uid': string
}

interface JWTContextType {
  jwt: string | undefined
  authHeaders: AuthHeadersCookie | undefined
  refetch: () => Promise<String | undefined>
}

const JWTContext = createContext<JWTContextType>({
  jwt: undefined,
  authHeaders: undefined,
  refetch: async () => undefined,
})

export const JWTProvider: React.FC<React.PropsWithChildren> = memo(
  ({ children }) => {
    const authHeaders = getAuthHeaders()
    const { data, refetch } = useSuspenseQuery<Query>(
      GET_JWT,
      authHeaders
        ? {
            variables: {
              accessToken: authHeaders['access-token'],
              tokenType: authHeaders['token-type'],
              client: authHeaders.client,
              expiry: authHeaders.expiry,
              uid: authHeaders.uid,
            },
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          }
        : skipToken,
    )
    const [extendJwt, { data: extendJwtData }] =
      useMutation<Mutation>(EXTEND_JWT)

    const jwt = extendJwtData?.extendJwt.jwt || data?.getJwt?.jwt
    jwtRef.current = jwt

    const contextValue = useMemo(() => {
      const contextValue: JWTContextType = {
        jwt,
        authHeaders,
        refetch: () => {
          // This is a little funky.
          // We want the signature of refetch to just be:
          // `() => Promise<string | undefined>`
          // simple enough with an async function, but we need to use
          // `startTransition` to avoid triggering the suspense boundary.
          // And `startTransition` neither returns the result of the passed
          // function nor accepts an async function.
          let resolve: (value: string | undefined) => void

          let promise = new Promise<string | undefined>((r) => {
            resolve = r
          })

          startTransition(() => {
            refetch().then(({ data }) => {
              const jwt = data?.getJwt?.jwt
              resolve(jwt)
            })
          })

          return promise
        },
      }
      return contextValue
    }, [authHeaders, jwt, refetch])

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout> | undefined

      if (jwt) {
        const parsed = parsePbxxJwt(jwt)

        if (parsed?.exp) {
          const now = new Date().valueOf() / 1000
          // 100s before expiration
          const ttl = (parsed.exp - now - 100) * 1000

          timeout = setTimeout(async () => {
            const { data } = await extendJwt({
              variables: {
                jwt,
              },
            })

            if (data?.extendJwt?.jwt) {
              jwtRef.current = data.extendJwt.jwt
            }
          }, ttl)
        }
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }, [extendJwt, jwt])

    return (
      <JWTContext.Provider value={contextValue}>{children}</JWTContext.Provider>
    )
  },
)

export const useJWT = () => {
  const context = useContext(JWTContext)

  return context
}

const EXTEND_JWT = gql`
  mutation extendJwtTTL($jwt: String!) {
    extendJwt(jwt: $jwt) {
      jwt
    }
  }
`

const GET_JWT = gql`
  query getJwtFromHeaders(
    $accessToken: String!
    $tokenType: String!
    $client: String!
    $expiry: String!
    $uid: String!
  ) {
    getJwt(
      accessToken: $accessToken
      tokenType: $tokenType
      client: $client
      expiry: $expiry
      uid: $uid
    ) {
      jwt
    }
  }
`

function getAuthHeaders() {
  const authHeadersString = jsCookie.get('auth_headers')

  if (authHeadersString) {
    // guard against malformed JSON.
    try {
      const authHeaders: AuthHeadersCookie = JSON.parse(authHeadersString)

      if (
        !authHeaders.expiry ||
        new Date(Number(authHeaders.expiry) * 1000) < new Date()
      ) {
        return
      }

      return authHeaders
    } catch (err) {
      console.error(err)
    }
  }

  return
}
