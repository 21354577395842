import { useRouteMatch } from 'react-router'

function useRetailerAccountSlugFromRoute() {
  const match = useRouteMatch<{
    retailerAccountSlug?: string
    feature?: string
  }>('/retailer/:feature?/:retailerAccountSlug?')
  const feature = match?.params?.feature
  const retailerAccountSlug = match?.params?.retailerAccountSlug

  if (feature === 'accountSelector') {
    return
  }

  return retailerAccountSlug
}

export default useRetailerAccountSlugFromRoute
