import { datadogRum } from '@datadog/browser-rum'
import type { TracingOption } from '@datadog/browser-rum-core/src/domain/tracing/tracer.types'

import AppConfig from '../AppConfig'

import fullstoryService from './fullstory'
import { Service } from './index'

declare global {
  interface Window {
    DD_RUM?: any
  }
}

const datadog: Service<typeof datadogRum> = {
  async init() {
    if (
      AppConfig.DATADOG_APPLICATION_ID &&
      AppConfig.DATADOG_CLIENT_TOKEN &&
      AppConfig.DATADOG_SITE &&
      AppConfig.DATADOG_SERVICE &&
      AppConfig.DATADOG_ENV &&
      AppConfig.DATADOG_TRACING_ORIGINS
    ) {
      datadogRum.init({
        applicationId: AppConfig.DATADOG_APPLICATION_ID,
        clientToken: AppConfig.DATADOG_CLIENT_TOKEN,
        // `site` is a string union and needs a type assertion.
        // @ts-expect-error
        site: AppConfig.DATADOG_SITE,
        service: AppConfig.DATADOG_SERVICE,
        env: AppConfig.DATADOG_ENV,
        sampleRate: 100,
        replaySampleRate: 100,
        trackInteractions: true,
        allowedTracingUrls: normalizeTracingUrls(
          AppConfig.DATADOG_TRACING_ORIGINS,
        ),
      })

      datadogRum.startSessionReplayRecording()
    }
  },
  loaded() {
    return window.DD_RUM !== undefined
  },
  instance() {
    return window.DD_RUM
  },
  identify(user) {
    let fullStorySession =
      fullstoryService?.instance()?.getCurrentSession?.() || ''
    if (this.loaded()) {
      datadogRum.setUser({
        ...user,
        id: `${user?.id}`,
        name: `${user?.firstName} ${user?.lastName}`,
        email: `${user?.email}`,
        fullStorySession,
      })
    }
  },
}

function normalizeTracingUrls(urls: string[] | undefined) {
  if (!urls) {
    return
  }

  const options = urls.map((x) => {
    const tracingOption: TracingOption = {
      match: x,
      propagatorTypes: ['datadog', 'tracecontext'],
    }

    return tracingOption
  })

  return options
}

export default datadog
