import { gql } from '@apollo/client'

export const RETAILER_ACCOUNT_FIELDS = [
  // So much of the API is based on retailer IDs, but it's easy to argue this
  // should also be its own context.
  'retailers',
  'retailers.brand',

  // TODO Should be relegated to some "retailer account socials" context.
  'facebook_pages',
  'facebook_pages.remote_token_status',
  'twitter_accounts',
  'retailers.facebook_pages',
  'retailers.twitter_accounts',

  // TODO Not sure where this is used.
  'retailers.brand.settings',

  // TODO Only used in DAM.
  'retailers.brand.digital_asset_tags',

  // TODO Only used in Suggest Brands.
  'retailers.brand.industry_list',
]

export const USER_FIELDS = [
  'retailer_accounts',
  // ...RETAILER_ACCOUNT_FIELDS.map((x) => `retailer_accounts.${x}`),
  // Requesting either of these fields causes them to be the _only_ thing
  // returned.
  // "user_roles",
]

export const USER_FIELDS_WITH_RETAILER_ACCOUNT_FIELDS = USER_FIELDS.concat(
  RETAILER_ACCOUNT_FIELDS.map((x) => `retailer_accounts.${x}`),
)

export const RETAILER_ACCOUNT_FIELDS_FRAGMENT = gql`
  fragment RetailerAccountFieldsFragment on RetailerAccount {
    id
    name
    slug
    multi_brand
    website
    phone
    phone_extension
    logo_file_name
    logo_content_type
    logo_file_size
    logo_updated_at
    logo_url
    logo_thumbnail_url
    logo_medium_url
    formatted_phone
    formatted_phone_extension
    show_multi_brand_ad
    business_id

    facebook_pages {
      id
      name
      picture_url
      instagram_business_account_id
      business_manager_permissions
      remote_token_status {
        is_valid
      }
    }

    twitter_accounts {
      description
      follower_count
      has_been_authenticated
      id
      name
      nickname
      profile_image_url
    }

    retailers {
      id
      name
      phone
      website
      created_at
      updated_at
      logo_file_name
      logo_content_type
      logo_file_size
      logo_updated_at
      slug
      brand_id
      deleted_at
      phone_extension
      state
      created_by_id
      updated_by_id
      primary_user_id
      retailer_import_id
      hash_id
      logo_url
      logo_thumbnail_url
      logo_medium_url
      multi_brand
      retailer_account_id
      location_count
      paid_ad_radius

      facebook_pages {
        id
        name
        picture_url
        business_manager_permissions
        instagram_business_account_id
      }

      twitterAccounts {
        description
        follower_count
        has_been_authenticated
        id
        name
        nickname
        profile_image_url
      }

      brand {
        id
        name
        addr1
        addr2
        city
        state
        zip
        website
        about
        created_at
        updated_at
        logo_file_name
        logo_content_type
        logo_file_size
        logo_updated_at
        slug
        retailer_alias
        campaign_alias
        division_alias
        abbr
        invite_from_name
        engagement_flow_header_text
        support_email_address
        logo_url
        phone
        email
        dealer_funded_management_fee_percentage
        retailer_dashboard_message
        retailer_self_fund_auto_enabled
        status
        header_background_color
        header_foreground_color
        show_manage_brands_link
        holding_company_id
        ads_enabled
        auto_share_facebook
        auto_share_instagram
        auto_share_twitter
        instagram_enabled
        lock_retailer_data
        external_auth_login_url
        waiting_room_message
        show_logo
        instagram_ads_enabled
        default_enrollment_campaign_id
        wallet_enabled
        division_report_summary_enabled
        default_registration_key_id
        fb_restrict21plus
        activity_feeds_enabled
        activity_reports_enabled
        brand_report_summary_enabled
        campaigns_open_enrollment_by_default
        custom_header_css
        default_timeout_in_minutes
        facebook_id
        retailer_report_summary_enabled
        show_message_campaigns
        twitter_id
        demo
        campaign_builder_enabled
        sso_name
        sso_url
        include_in_engagement_reminder
        new_designer_enabled
        lock_user_creation
        uuid
        hubspot_portal_id
        offboard_redirect
        region_data_stored_in_custom_fields
        accelerate_campaign_automation_enabled
        hubspot_company_id
        hubspot_company_url
        community
        digital_asset_tags
        locales

        settings {
          template_campaigns_enabled
          frame_campaigns_enabled
          simple_campaigns_enabled
          product_campaigns_enabled
          data_campaigns_enabled
          retailer_share_scheduling_enabled
          assets_enabled
          match_enabled
          mobile_ads_enabled
          facebook_ads_enabled
          google_ads_enabled
          retailer_match_funding_enabled
          instamatch_enabled
          brand_content_discovery
          retailer_content_discovery
          share_content_filters
          ss_facebook_allow_prefill
          ss_facebook_allow_append_link_to_message
          ss_facebook_allow_custom_link
          ss_allow_custom_link
          network_monitoring
          show_expired_brand_message
          allow_match_custom_message
          limit_single_retailer_location
          ss_landing_page_campaign
          paid_campaigns_enabled
          campaign_append_to_retailer_website_enabled
          retailer_connection_requests_industry_suggestions_enabled
          retailer_connection_requests_pic_suggestions_enabled
        }
      }
    }
  }
`

export const USER_FIELDS_FRAGMENT = gql`
  ${RETAILER_ACCOUNT_FIELDS_FRAGMENT}
  fragment UserFieldsFragment on User {
    id
    email
    created_at
    updated_at
    first_name
    last_name
    title
    company
    time_zone
    mobile_phone_number
    clickwrap_accepted_at
    created_by_id
    updated_by_id
    type
    slug
    deactivated_by
    time_zone_offset
    time_zone_abbr
    time_zone_iana_name
    user_roles
    language_preference

    retailer_accounts {
      ...RetailerAccountFieldsFragment
    }

    uuid
  }
`

export const GET_USER = gql`
  ${USER_FIELDS_FRAGMENT}
  query getUserSaneLogin($id: ID!, $fields: [String!]) {
    getUser(id: $id, fields: $fields) {
      ...UserFieldsFragment
    }
  }
`

export const GET_RETAILER_ACCOUNT = gql`
  ${RETAILER_ACCOUNT_FIELDS_FRAGMENT}
  query getRetailerAccountSaneLogin($id: ID!, $fields: [String!]) {
    getRetailerAccount(id: $id, fields: $fields) {
      ...RetailerAccountFieldsFragment
    }
  }
`

export const GET_JWT = gql`
  query getJwtSaneLogin(
    $accessToken: String!
    $tokenType: String!
    $client: String!
    $expiry: String!
    $uid: String!
  ) {
    getJwt(
      accessToken: $accessToken
      tokenType: $tokenType
      client: $client
      expiry: $expiry
      uid: $uid
    ) {
      jwt
    }
  }
`
