import { Box, CircularProgress, Stack } from '@mui/material'

import ReactComponent from '@src/svgs/legacy/boxx-blue.svg?react'

const Loader: React.FC = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      height="100vh"
    >
      <ReactComponent width={200} />

      <Box color="grey.400">
        <CircularProgress
          variant="indeterminate"
          disableShrink
          color="inherit"
          size={80}
          thickness={2.5}
        />
      </Box>
    </Stack>
  )
}

export default Loader
