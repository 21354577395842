import * as Facebook from 'fb-sdk-wrapper'
import { memo } from 'react'

import loadCrowdinTranslations from '@src/i18n/loadCrowdinTranslations'
import { wrapPromiseForSuspense } from '@src/lib/wrapPromiseForSuspense'

import AppConfig from '../../../config/AppConfig'

async function initFacebook() {
  try {
    await Facebook.load()
    // @ts-expect-error
    Facebook.init({
      appId: AppConfig.FACEBOOK_APP_ID,
    })
  } catch (err) {
    console.error(err)
  }
}

const facebookPromise = wrapPromiseForSuspense(initFacebook())

async function loadTranslations() {
  try {
    await loadCrowdinTranslations()
  } catch (err) {
    console.error(err)
  }
}

const translationPromise = wrapPromiseForSuspense(loadTranslations())

const AppInitializer: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <>
      {facebookPromise.read().status === 'success' &&
      translationPromise.read().status === 'success'
        ? props.children
        : null}
    </>
  )
}

export default memo(AppInitializer)
