import { ApolloProvider } from '@apollo/client'
import { CloudinaryContext } from 'cloudinary-react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { SnackbarProvider } from 'notistack'
import { lazy, Suspense, useEffect, useRef } from 'react'
import { Router } from 'react-router-dom'

import AnalyticsLoader from '@src/analytics/AnalyticsLoader'
import ForceScrollbar from '@src/lib/ForceScrollbar'
import history from '@src/lib/history'
import {
  SimpleConfirmDialog,
  SimpleConfirmDialogProvider,
} from '@src/lib/SimpleConfirmDialog'

import { apolloClient } from '../config/ApolloClient'
import AppConfig from '../config/AppConfig'
import ThemeProvider from '../lib/ThemeProvider'

import AuthHandler from './auth/AuthHandler'
import { JWTProvider } from './auth/JwtProvider'
import AppInitializer from './shared/AppInitializer'
import Loader from './shared/Loader'

// Since our translations aren't reactive, we need to hold off on importing a
// large chunk of our app until a user is loaded and the translation system is
// fully set up.
const BaseRoutes = lazy(() => import('./routing/BaseRoutes'))

const App = (props) => {
  const previousLocationRef = useRef({
    pathname: '',
    search: '',
  })

  useEffect(() => {
    const unlisten = history.instance().listen(({ pathname, search }) => {
      if (
        (pathname.includes('/retailer/content/') &&
          pathname.includes('paid-ad')) ||
        (previousLocationRef.current.pathname.includes('/retailer/content/') &&
          previousLocationRef.current.pathname.includes('paid-ad')) ||
        search.includes('shareSettingId=') ||
        previousLocationRef.current.search.includes('shareSettingId=') ||
        search.includes('shareAssetId=') ||
        previousLocationRef.current.search.includes('shareAssetId=') ||
        search.includes('editShareId=') ||
        previousLocationRef.current.search.includes('editShareId=')
      ) {
        previousLocationRef.current = { pathname, search }
        return
      }

      previousLocationRef.current = { pathname, search }

      //This method scrolls the page to the top when a route is changed.
      //Otherwise in a SPA the page might by default stay scrolled to its current position.
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      })
    })

    return unlisten
  }, [])

  return (
    <Router history={history.instance()}>
      <Suspense fallback={<Loader />}>
        <AppInitializer>
          <ApolloProvider client={apolloClient()}>
            <JWTProvider>
              <AuthHandler>
                <CloudinaryContext
                  cloudName={AppConfig.CLOUD_NAME}
                  includeOwnBody
                >
                  <ThemeProvider mode="light">
                    <SnackbarProvider
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      maxSnack={3}
                    >
                      <SimpleConfirmDialogProvider>
                        <>
                          <ForceScrollbar />
                          <AnalyticsLoader />

                          <BaseRoutes />
                          <SimpleConfirmDialog />
                        </>
                      </SimpleConfirmDialogProvider>
                    </SnackbarProvider>
                  </ThemeProvider>
                </CloudinaryContext>
              </AuthHandler>
            </JWTProvider>
          </ApolloProvider>
        </AppInitializer>
      </Suspense>
    </Router>
  )
}

export default withLDProvider({
  clientSideID: AppConfig.LAUNCH_DARKLY_APP_ID,
  context: {
    key: 'anonymous@promoboxx.com',
    anonymous: true,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(App)
